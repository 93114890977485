import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import Container from 'components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithubAlt, faVuejs, faNodeJs, faReact, faSass, faJsSquare, faSketch, faAccessibleIcon, faAdobe, faRust } from '@fortawesome/free-brands-svg-icons';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons'

const Description = styled.p`
  margin-bottom: 10px;
  display: inline-block;
`
const IconContainer = styled.span`
  display: inline-flex;
  justify-content: space-between;
  justify-content: ${props => props.spaceBetween ? 'space-between' : 'flex-start'};
  align-content: center;
  width: 50%;
`

export default function Index({ data: { site, allMdx } }) {
  return (
    <Layout site={site}>
      <Hero
        heading="about"
        subHeading="I’m Alan, a Software Engineer from Arrochar, near Loch Lomond. I dig Design Systems, & making things on the web."
        blog={false}
        avatar={true}
      />
      <Container>
        <h2>FULLSTACK WEB DEVELOPMENT</h2>
        <Description>Fullstack Typescript, React, and Vue. Graphql API design, Modular CSS  architecture, Experienced with the JAM stack. Postgres, Azure Serverless and Terraform. Learning Rust</Description>
        <IconContainer spaceBetween>
          <FontAwesomeIcon icon={faJsSquare} size="lg" />
          <FontAwesomeIcon icon={faNodeJs} size="lg" />
          <FontAwesomeIcon icon={faVuejs} size="lg" />
          <FontAwesomeIcon icon={faReact} size="lg" />
          <FontAwesomeIcon icon={faSass} size="lg" />
          <FontAwesomeIcon icon={faGithubAlt} size="lg" />
          <FontAwesomeIcon icon={faRust} size="lg" />
        </IconContainer>
        <h2>Design Systems</h2>
        <Description>I’ve been involved in building simple mobile first responsive interfaces to complex commercial applications for the web and mobile.</Description>

        <IconContainer>
          <FontAwesomeIcon css={{
            marginRight: '1rem'
          }}
            icon={faSketch} size="lg" />
          <FontAwesomeIcon icon={faAdobe} size="lg" />
        </IconContainer>
        <h2>UX and accessibility </h2>
        <Description>I’m a believer in accessibility on the web. I’ve setup and automated Pa11y tests and audits to ensure that software complies with WCAG guidelines</Description>

        <IconContainer>
          <FontAwesomeIcon icon={faAccessibleIcon} size="lg" />
        </IconContainer>
        <h2>Industry Experience </h2>
        <Description>As an experienced software developer and team leader with a background in the Public Sector, Financial Services, Digital Identity, and Energy. I've delivered high-quality software solutions that meet business needs across a diverse range of products and industries.</Description>

        <IconContainer>
          <FontAwesomeIcon icon={faBusinessTime} size="lg" />
        </IconContainer>
      </Container>

    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`
